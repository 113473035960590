export interface Prefetch {
  regexp: string
  path: string
}

export interface Page {
  title: string
  description: string
  path: string
  imageUrl: string
  source: string
  prefetch: Prefetch[]
  type?: string
}

export const emptyPage: Page = {
  title: '',
  description: '',
  path: '',
  imageUrl: '',
  source: '',
  prefetch: [],
  type: 'website',
}
