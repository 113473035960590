import {
  Component,
  ElementCreator,
  elements,
  vars,
  varDefault,
  getListItem,
  xinProxy,
  touch,
} from 'xinjs'
import {
  xinFloat,
  xinSizer,
  icons,
  postNotification,
  popMenu,
  CodeEditor,
} from 'xinjs-ui'
import { uploadFile, listFiles, pathToUrl, deleteFile } from './firebase'

const altText = (fileName: string): string => {
  let [text] = fileName.split('.')
  return text.replace(/[-_]/g, ' ')
}

export const escapeHTMLAttribute = (value: string): string => {
  return value.replace(/"/g, '&quot;').replace(/'/g, '&apos;')
}

const { h4, label, input, button, div, select, option, span, template } =
  elements

export const fileType = (path: string): string => {
  switch (path.split('.').pop()) {
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
    case 'webp':
    case 'gif':
      return 'image'
    case 'mov':
    case 'mp4':
    case 'm4v':
      return 'video'
    default:
      return 'special'
  }
}

export interface Asset {
  name: string
  path: string
}

const { assetManagerData } = xinProxy(
  {
    assetManagerData: {
      files: [] as Asset[],
      filter: '',
      get filteredFiles(): Asset[] {
        const filter = assetManagerData.filter.toLocaleLowerCase()
        const { files } = assetManagerData
        return filter === ''
          ? files
          : files.filter(
              (asset: Asset) =>
                asset.name.toLocaleLowerCase().includes(filter) ||
                asset.path.toLocaleLowerCase().includes(filter)
            )
      },
    },
  },
  true
)
class AssetManager extends Component {
  popItemMenu = (event: Event) => {
    const target = event.target as HTMLElement
    const file = getListItem(target)
    console.log(target, file)
    const codeEditor = document.querySelector(
      'xin-post-editor xin-code'
    ) as CodeEditor | null
    const { getFiles } = this
    popMenu({
      target,
      menuItems: [
        {
          caption: codeEditor ? 'Insert Markdown' : 'Copy Markdown',
          icon: codeEditor ? 'code' : 'copy',
          async action() {
            const url = await pathToUrl(file.path)
            const code = `![${altText(file.name)}](${url})`
            if (codeEditor) {
              const { editor } = codeEditor
              editor.session.insert(editor.getCursorPosition(), code)
            } else {
              navigator.clipboard.writeText(code)
            }
          },
        },
        {
          caption: codeEditor ? 'Insert HTML' : 'Copy HTML',
          icon: codeEditor ? 'code' : 'copy',
          async action() {
            const url = await pathToUrl(file.path)
            const code = `<img alt="${escapeHTMLAttribute(
              altText(file.name)
            )}" src="${url}">`
            if (codeEditor) {
              const { editor } = codeEditor
              editor.session.insert(editor.getCursorPosition(), code)
            } else {
              navigator.clipboard.writeText(code)
            }
          },
        },
        {
          caption: 'Copy URL',
          icon: 'link',
          async action() {
            const url = await pathToUrl(file.path)
            if (url) {
              navigator.clipboard.writeText(url)
              postNotification({
                type: 'info',
                message: `url for "${file.name}" copied to clipboard`,
              })
            }
          },
        },
        null,
        {
          caption: 'View',
          icon: 'eye',
          async action() {
            const url = await pathToUrl(file.path)
            window.open(url)
          },
        },
        null,
        {
          caption: 'Delete',
          icon: 'trash',
          async action() {
            if (confirm(`Delete "${file.path}"?`)) {
              if (await deleteFile(file.path)) {
                postNotification({
                  type: 'info',
                  message: `File ${file.path} was has ceased to be. Bereft of life, it rests in peace. It is a an ex-file.`,
                })
              } else {
                postNotification({
                  type: 'error',
                  message: `${file.path} was not deleted, or was already deleted by someone else. Who knows…?`,
                })
              }
              await getFiles()
            }
          },
        },
      ],
    })
  }

  uploadFile = () => {
    const { fileInput, pathSelector, filePath, convertToWebP } = this.parts as {
      fileInput: HTMLInputElement
      pathSelector: HTMLSelectElement
      filePath: HTMLInputElement
      convertToWebP: HTMLInputElement
    }
    const basePath = pathSelector.value
    if (fileInput.files?.length === 1) {
      const file = fileInput.files[0]
      uploadFile(
        file,
        `/${basePath}/${filePath.value}`,
        convertToWebP.checked
      ).then((path: string) => {
        postNotification({
          type: 'info',
          message: `${file.name} uploaded to path '${path}'`,
        })
        filePath.value = ''
        fileInput.value = ''
        this.getFiles()
      })
    } else {
      postNotification({
        type: 'error',
        message: 'Pick a file first!',
      })
    }
  }

  setPath = () => {
    const { fileInput, filePath } = this.parts as {
      fileInput: HTMLInputElement
      filePath: HTMLInputElement
    }
    if (fileInput.files?.length === 1) {
      filePath.value = fileInput.files[0].name.replace(/\s+/g, '-')
    } else {
      filePath.value = ''
    }
  }

  getFiles = async () => {
    const { pathSelector, assetList } = this.parts as {
      pathSelector: HTMLSelectElement
      assetList: HTMLElement
    }
    try {
      assetManagerData.files = await listFiles(pathSelector.value)
      touch(assetManagerData.filteredFiles)
    } catch (e) {
      postNotification({
        type: 'error',
        message: `Error: ${e}`,
      })
    }
  }

  updateList = () => {
    touch(assetManagerData.filteredFiles)
  }

  content = () =>
    xinFloat(
      {
        class: 'compact',
        drag: true,
        style: {
          bottom: '10px',
          left: '10px',
          maxWidth: 'calc(100% - 20px)',
          minHeight: '200px',
          minWidth: '300px',
          width: '400px',
          overflow: 'hidden',
        },
      },
      h4('Asset Manager', {
        class: 'primary',
        style: { textAlign: 'center', padding: vars.spacing75, margin: 0 },
      }),
      div(
        { class: 'row', style: { padding: vars.spacing } },
        input({
          type: 'search',
          placeholder: 'filter items',
          bindValue: assetManagerData.filter,
          onChange: this.updateList,
        }),
        span({ class: 'elastic' }),
        label(
          {
            class: 'row no-drag',
            style: {
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: `${vars.spacing50} ${vars.spacing}`,
            },
          },
          span('Path'),
          select(
            { part: 'pathSelector', onChange: this.getFiles },
            option('blog', { selected: true }),
            option('public')
          )
        )
      ),
      div(
        {
          part: 'assetList',
          class: 'column elastic no-drag',
          style: {
            height: '300px',
            overflow: 'hidden scroll',
            alignItems: 'stretch',
            content: ' ',
            margin: `${vars.spacing50} ${vars.spacing}`,
          },
          bindList: {
            value: assetManagerData.filteredFiles,
            idPath: 'path',
          },
        },
        template(
          div(
            { class: 'row', style: { alignItems: 'center' } },
            span({ class: 'text-nowrap elastic', bindText: '^.name' }),
            button(
              {
                title: 'Options',
                onClick: this.popItemMenu,
              },
              icons.moreVertical()
            )
          )
        )
      ),
      div(
        {
          class: 'column no-drag',
          style: {
            alignItems: 'stretch',
            padding: vars.spacing,
            gap: vars.spacing50,
          },
        },
        label(
          { class: 'row', style: { alignItems: 'center', padding: 0 } },
          span('File'),
          input({
            part: 'fileInput',
            type: 'file',
            onChange: this.setPath,
            class: 'elastic',
          })
        ),
        label(
          { class: 'row', style: { alignItems: 'center', padding: 0 } },
          span('Path'),
          input({
            placeholder: 'File Name',
            part: 'filePath',
            class: 'elastic',
          })
        ),
        div(
          { class: 'row' },
          label(
            input({ part: 'convertToWebP', checked: true, type: 'checkbox' }),
            span('Convert to webP')
          ),
          span({ class: 'elastic' }),
          button(
            {
              class: 'row',
              style: {
                alignSelf: 'center',
                alignItems: 'center',
                gap: vars.spacing50,
              },
            },
            span('Upload'),
            icons.upload(),
            {
              onClick: this.uploadFile,
            }
          )
        )
      ),
      xinSizer({ class: 'no-drag' }),
      button(
        {
          title: 'close asset manager',
          target: 'asset-manager',
          class: 'iconic no-drag',
          style: {
            position: 'absolute',
            top: 0,
            right: 0,
          },
          onClick: this.remove.bind(this),
        },
        icons.x()
      )
    )

  connectedCallback() {
    super.connectedCallback()

    this.getFiles()
  }
}

export const assetManager = AssetManager.elementCreator({
  tag: 'asset-manager',
  styleSpec: {
    ':host': {
      _spacing: varDefault.pad('10px'),
    },
    ':host xin-sizer': {
      _resizeIconFill: vars.textColor,
    },
    ':host xin-float': {
      background: vars.panelBg,
      display: 'flex',
      flexDirection: 'column',
    },
    ':host .text-nowrap': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}) as ElementCreator<AssetManager>
